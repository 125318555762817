export const SNOW_CH_PREFIX = 'snow:CH:';
export const SOLAR_CH_PREFIX = 'solar:CH:';
export const SNOW_RG_PREFIX = 'snow:RG:';
export const SOLAR_RG_PREFIX = 'solar:RG:';
export const PATHS_STUDIO_PREFIX = 'pathStudio';
const KEYS = ['country', 'region', 'property', 'section', 'block', 'subsection', 'subrow'];

export const setLocalStorageItem = (key, value, prefix = '') => localStorage.setItem(`${prefix}${key}`, value);
export const removeLocalStorageItem = (key, prefix = '') => localStorage.removeItem(`${prefix}${key}`);
export const getLocalStorageItem = (key, prefix = '') => localStorage.getItem(`${prefix}${key}`);

export const getPageSavedSelections = (prefix = '') => {
  const savedItems = {};
  KEYS.forEach((key) => {
    savedItems[key] = parseInt(getLocalStorageItem(key, prefix)) || undefined;
  });
  savedItems.useCase = getLocalStorageItem('useCase');
  return savedItems;
};

export const removePageSavedSelections = (prefix = '') => {
  KEYS.forEach((key) => {
    localStorage.removeItem(`${prefix}${key}`);
  });
};
