/* eslint-disable camelcase */
import React, { useState } from 'react';
import {
  Checkbox,
  FormControlLabel,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  makeStyles
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  tableOuter: {
    border: `1px solid ${theme.palette.grey[500]}`,
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.background.paper
  },
  dimmed: {
    opacity: 0.5
  },
  tableHeadCell: {
    fontWeight: 'bold',
    backgroundColor: theme.palette.grey[300],
    color: theme.palette.text.primary
  },
  tableRow: {
    backgroundColor: theme.palette.grey[100],
    '&:not(:last-child)': {
      borderBottom: `1px solid ${theme.palette.grey[300]}`
    }
  },
  orderIndexCell: {
    width: '50%'
  },
  updateRowCell: {
    width: '50%'
  },
  enableCheckboxLabel: {
    fontSize: '1rem',
    color: 'black'
  }
}));

/**
 * Renders a small table for selecting subrows to update.
 *
 * @param {Object[]} data -
 * @param {Function} setSubrowsToModify - Callback function receiving the list of checked orderIndex values
 */
const SubrowsUpdateTable = ({ data, setSubrowsToModify, isOpenArea }) => {
  const classes = useStyles();
  const [enableSubrowsUpdate, setEnableSubrowsUpdate] = useState(false);
  const [checkedIndexes, setCheckedIndexes] = useState([]);

  /**
   * Toggles the entire feature on/off.
   * - When turning it off, we also clear all checked rows.
   */
  const handleToggleEnable = (event) => {
    const isEnabled = event.target.checked;
    setEnableSubrowsUpdate(isEnabled);
    if (!isEnabled) {
      setCheckedIndexes([]);
      setSubrowsToModify([]);
    }
  };

  /**
   * Toggles a specific row’s checkbox by its orderIndex.
   */
  const handleCheckboxChange = (orderIndex) => {
    if (!enableSubrowsUpdate) return; // Ignore clicks when disabled

    let updatedIndexes;
    if (checkedIndexes.includes(orderIndex)) {
      updatedIndexes = checkedIndexes.filter((idx) => idx !== orderIndex);
    } else {
      updatedIndexes = [...checkedIndexes, orderIndex];
    }

    setCheckedIndexes(updatedIndexes);
    setSubrowsToModify(updatedIndexes);
  };

  return (
    <div className={classes.container}>
      <FormControlLabel
        control={<Checkbox checked={enableSubrowsUpdate} onChange={handleToggleEnable} color="primary" disabled={isOpenArea} />}
        label={<Typography className={classes.enableCheckboxLabel}>Enable Subrows Update</Typography>}
      />

      <TableContainer className={`${classes.tableOuter} ${!enableSubrowsUpdate ? classes.dimmed : ''}`}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell className={`${classes.tableHeadCell} ${classes.orderIndexCell}`}>Order Index</TableCell>
              <TableCell className={`${classes.tableHeadCell} ${classes.updateRowCell}`}>Update Subrow</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row, idx) => {
              const { order_index } = row;
              const isChecked = checkedIndexes.includes(order_index);
              const componentKey = `${idx}_${order_index}`;

              return (
                <TableRow key={componentKey} className={classes.tableRow}>
                  <TableCell className={classes.orderIndexCell}>{order_index}</TableCell>
                  <TableCell className={classes.updateRowCell}>
                    <Checkbox
                      checked={isChecked}
                      onChange={() => handleCheckboxChange(order_index)}
                      color="secondary"
                      disabled={!enableSubrowsUpdate}
                    />
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default SubrowsUpdateTable;
