/* eslint-disable no-unused-expressions */
import {
  Button,
  FormControl,
  Grid,
  makeStyles,
  Typography,
  TextField,
  Fade,
  Select,
  MenuItem,
  Box,
  Tooltip,
  FormControlLabel,
  Checkbox
} from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import React, { useState, useRef, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { useStores } from '../store/root/root.store';
import { ROUTE_PATHS_STUDIO } from './routes';
import { generateSubRowsS3, uploadSubBlockSubrowsFile } from '../services/api/subrows.service';
import {
  INTER_ROW_MODES,
  GENERATE_METHODS,
  GENERATE_METHODS_OPTIONS,
  VEGETATION_CONDITION,
  WEATHER_CONDITION,
  PATH_PARAMS_DEFAULT_VALUES,
  PATH_PARAMS_MAP
} from '../utils/constants';
import { AlertDialog } from '../components/dialogs/alert-dialog.dialog';
import ProgressModal from '../components/dialogs/ProgressModal';
import { addSubBlock, getSavedPathGenParameters, savePathParameters, updateSubBlockMeta } from '../services/api/subblocks.service';
import PathGenerationParamsDisplay from '../components/dialogs/PathGenParamsDisplay';
import SaveParameterDialog from '../components/dialogs/PathGenParamsSave';
import SubrowsUpdateTable from '../components/tables/PathStudioSubrowEditTable';
import { RobotsMapView } from '../components/maps/robots-map-view';
import { ROUTE_TEACH_ROUTE } from './routes';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(8),
    padding: theme.spacing(2),
    minHeight: 'calc(100vh - 64px)'
  },
  formWrapper: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(3),
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[2],
    marginBottom: theme.spacing(2)
  },
  inputRows: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    marginBottom: theme.spacing(2)
  },
  inputColumn: {
    flex: 1,
    maxWidth: 260,
    marginRight: theme.spacing(2)
  },
  inputField: {
    marginBottom: theme.spacing(2)
  },
  savedParamsRow: {
    display: 'flex',
    alignItems: 'center',
    '& > *:not(:last-child)': {
      marginRight: theme.spacing(2)
    }
  },
  savedParamButtonsGroup: {
    display: 'flex',
    alignItems: 'flex-start',
    '& > *': {
      marginRight: theme.spacing(1)
    }
  },
  csvAndGenerateGroup: {
    display: 'flex',
    alignItems: 'center',
    '& > *': {
      marginRight: theme.spacing(2)
    }
  },
  subrowsContainer: {
    marginTop: theme.spacing(3),
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[1],
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(2)
  },
  rightColumn: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    height: '100%'
  },
  mapButtonsRow: {
    display: 'flex',
    marginTop: theme.spacing(2),
    '& > *': {
      marginRight: theme.spacing(1)
    }
  }
}));

export const GenerateSolarPathsPage = observer(() => {
  const classes = useStyles();
  const { push } = useHistory();
  const location = useLocation();
  const { surveyFile, surveyFileURL, pathParams, subBlockSelected } = location.state;
  const { regionsStore, blocksStore, chaperonePropertyStore, subBlocksStore, subrowStore } = useStores();
  const selectedSubBlockName = subBlocksStore.getSelectedSubBlock()?.name;
  const selectedSubBlockId = subBlocksStore.getSelectedSubBlock()?.id;
  const selectedSubrows = subrowStore.subrows;
  const containerReference = useRef(null);
  const [subBlockName, setSubBlockName] = useState(selectedSubBlockName);
  const [showGenerateMethodType, setShowGenerateMethodType] = useState(false);
  const [showInvalidPilePointAlert, setShowInvalidPilePointAlert] = useState(false);
  const [generationMethodTypeOptions, setGenerationMethodTypeOptions] = useState([]);
  const [fileInput, setFileInput] = useState([]);
  const [twinSubBlockId, setTwinSubBlockId] = useState();
  const twinSubBlockOptions = subBlocksStore.getTwinSubBlocks() || [];
  const [twinSubBlockRowOffset, setTwinSubBlockRowOffset] = useState(0);
  const [selectedSavedParam, setSelectedSavedParam] = useState({ name: '', id: null });
  const [selectedSavedParamData, setSelectedSavedParamData] = useState({});
  const [showSavedParametersData, setShowSavedParametersData] = useState(false);
  const [subrowsToModify, setSubrowsToModify] = useState([]);
  const [plotSolarBlocks, setPlotSolarBlocks] = useState(0);
  const [isMapReset, setIsMapReset] = useState(false);
  const [isOpenArea, setIsOpenArea] = useState(false);
  // state variables for progress dialogue
  const [loading, setLoading] = useState(false);
  const [progressStatus, setProgressStatus] = useState('');
  const [progressMessage, setProgressMessage] = useState('');
  const [showSaveParamsDialogue, setShowSaveParamsDialogue] = useState(false);
  const [subBlockSavedParameters, setSubBlockSavedParameters] = useState(pathParams);
  const [openAreaId, setOpenAreaId] = useState();
  //  Initializes default value of form with default values.
  const [pathGenParams, setPathGenParams] = useState(
    () =>
      Object.entries(PATH_PARAMS_DEFAULT_VALUES).reduce((acc, [key, value]) => {
        acc[key] = value;
        return acc;
      }, {}),
    {}
  );

  const invalidPilePointMessage = {
    error: 'Invalid Pile Point Value',
    message: `Pile-Point cannot be smaller than 0 or larger than ${
      (pathGenParams.panelWidth * 60) / 2 || 'NA'
    }cm (Panel-Width/2). Please provide a valid value`
  };

  const setPathGenParameter = (name, value) => {
    if (name && value) {
      setPathGenParams((previous) => ({
        ...previous,
        [name]: value
      }));
    }
  };

  useEffect(() => {
    if (subBlockSavedParameters?.length > 0) {
      const firstEntry = subBlockSavedParameters[0];
      const { id, name } = firstEntry;
      setSelectedSavedParam({ id, name });
    }
  }, []);

  useEffect(() => {
    if (selectedSavedParam) {
      const result = subBlockSavedParameters.filter((paramEntry) => paramEntry.id === selectedSavedParam.id);
      setSelectedSavedParamData(result[0]);
    }
  }, [selectedSavedParam]);

  /**
   * Handles showing loading modal
   * @param {String} status will always be 'loading' in this function
   * @param {String} message Loading message
   */
  const handleShowProgressDialogue = (status, message) => {
    setLoading(true);
    setProgressStatus(status);
    setProgressMessage(message);
  };

  /**
   * Handles hidiing loading modal after success or error
   * @param {String} status success if API call returns 200, error otherwise
   * @param {String} message error or success message from API call or generic contact autonomy message
   */
  const handleHideProgressDialogue = (status, message) => {
    setProgressStatus(status);
    setProgressMessage(message);
    setTimeout(() => {
      setLoading(false);
    }, 3000);
  };

  /**
   * Function creates a sub-block and returns its ID or throws an error
   * @param {*} subBlockData
   * @returns
   */
  const handleCreateSubBlock = async (subBlockData) => {
    const subBlock = await addSubBlock(subBlockData);
    if (subBlock?.results?.id) {
      return subBlock.results?.id;
    }
    handleHideProgressDialogue('error', 'Error Occured While Creating SubBlock');
    throw new Error('This is an error to force an error');
  };

  // Returns to previous page for additional sub-block creation
  const handleReturnToSelectionPage = () => {
    setTimeout(() => {
      push(ROUTE_PATHS_STUDIO);
    }, 3000);
  };

  const handleEnterRouteTeachingPage = (openAreaID) => {
    push(ROUTE_TEACH_ROUTE, {
      openAreaID,
      regionId: regionsStore.selectedRegionId,
      propertyId: chaperonePropertyStore.selectedPropertyId,
      blockId: blocksStore.selectedBlockId
    });
  };
  /**
   * Function is responsible for handling waypoint generation
   * If the page is adding a new sub-block the sub-block is created first then path generator
   * creates the subrows
   * If updating a sub-block, a patch request is made first then path generator creates
   * and updates subrows as needed
   * Function is responsible for handling waypoint generation
   * If the page is adding a new sub-block the sub-block is created first then path generator
   * creates the subrows
   * If updating a sub-block, a patch request is made first then path generator creates
   * and updates subrows as needed
   */
  const handleGenerateSolarRows = async () => {
    try {
      let fileRemoteLocation;
      if (!isOpenArea) {
        handleShowProgressDialogue('loading', 'Generating Solar Paths...');
      } else {
        handleShowProgressDialogue('loading', 'Creating Open Area');
      }
      const blockId = blocksStore.selectedBlockId;
      await blocksStore.updateBlock({ blockId, panelWidth: pathGenParams?.panelWidth });
      if (!selectedSubBlockId && isOpenArea) {
        const result = await handleCreateSubBlock({
          blockId: blocksStore.selectedBlockId,
          name: subBlockName,
          drivewayWidth: pathGenParams?.driveLine,
          panelWidth: pathGenParams?.panelWidth,
          twinSubBlockId,
          twinSubBlockRowOffset,
          openArea: isOpenArea
        });
        if (result) {
          setOpenAreaId(result);
          handleHideProgressDialogue('success', 'Open Area Has Been Created');
          setTimeout(() => {
            handleEnterRouteTeachingPage(result);
          }, 3500);
        } else {
          handleHideProgressDialogue('error', 'Error Creating Open Area');
        }
        return;
      }
      let currentSelectedSubBlockId = selectedSubBlockId;
      if (currentSelectedSubBlockId === undefined) {
        currentSelectedSubBlockId = await handleCreateSubBlock({
          blockId: blocksStore.selectedBlockId,
          name: subBlockName,
          drivewayWidth: pathGenParams?.driveLine,
          panelWidth: pathGenParams?.panelWidth,
          twinSubBlockId,
          twinSubBlockRowOffset
        });
      }
      if (selectedSubBlockId) {
        // Updates metadata for existing sub-block (when sub-block is being updated not created)
        const updateResult = await updateSubBlockMeta(selectedSubBlockId, twinSubBlockId, twinSubBlockRowOffset);
        if (!updateResult) {
          throw new Error('Error Making API call');
        }
      }
      if (selectedSubBlockId) {
        // Updates metadata for existing sub-block (when sub-block is being updated not created)
        const updateResult = await updateSubBlockMeta(selectedSubBlockId, twinSubBlockId, twinSubBlockRowOffset);
        if (!updateResult) {
          throw new Error('Error Making API call');
        }
      }
      if (!surveyFile && !surveyFileURL && fileInput.length > 0) {
        const result = await uploadSubBlockSubrowsFile(
          fileInput[0],
          regionsStore.selectedRegionId,
          chaperonePropertyStore.selectedPropertyId,
          blocksStore.selectedBlockId,
          currentSelectedSubBlockId
        );
        fileRemoteLocation = result.data?.Location;
      } else {
        fileRemoteLocation = surveyFileURL;
      }
      const pathGenerationResult = await generateSubRowsS3(
        fileRemoteLocation,
        pathGenParams?.driveLine,
        pathGenParams?.panelWidth,
        pathGenParams?.drivewayWidth,
        pathGenParams?.deckToPileDistance,
        pathGenParams?.robotToPanelDistance,
        pathGenParams?.pathGenerationMethod,
        pathGenParams?.pathGenerationMethodType,
        pathGenParams?.targetOffset,
        pathGenParams?.vegetationThickness,
        pathGenParams?.weatherCondition,
        pathGenParams?.interRowMode,
        subrowsToModify
      );
      if (pathGenerationResult.data.statusCode === 200) {
        handleHideProgressDialogue('success', `Subrows have been generated for sub-block: ${subBlockName}`);
        handleReturnToSelectionPage();
      } else if (pathGenerationResult.data.statusCode === 400) {
        handleHideProgressDialogue('error', pathGenerationResult.data.body || 'Contact Autonomy Team');
      } else {
        throw new Error('This is an error to force an error');
      }
    } catch (error) {
      handleHideProgressDialogue('error', 'Error Occured While Generating Paths. Please Contact Autonomy Team');
      console.error(error);
    }
  };
  /** Controls rendering path generation method options &
   * adjust target offset based on generation method
   */
  useEffect(() => {
    if (pathGenParams?.pathGenerationMethod !== '') {
      if (pathGenParams?.pathGenerationMethod === 'coverage') {
        setGenerationMethodTypeOptions(GENERATE_METHODS_OPTIONS.coverage);
        setPathGenParameter('targetOffset', 70);
      } else if (pathGenParams?.pathGenerationMethod === 'lane_based') {
        setGenerationMethodTypeOptions(GENERATE_METHODS_OPTIONS.lane_based);
        setPathGenParameter('targetOffset', 132);
      }
      setShowGenerateMethodType(true);
    }
  }, [pathGenParams?.pathGenerationMethod]);

  /**
   * Resets all path generation parameters to their default values
   */
  const handleResetParameters = () => {
    setPathGenParams(
      Object.entries(PATH_PARAMS_DEFAULT_VALUES).reduce((acc, [key, value]) => {
        acc[key] = value;
        return acc;
      }, {})
    );
  };

  /**
   * Handles modifying the target selected param object
   * @param {Number} savedParamId ID
   * @param {String} savedParamName Given name
   */
  const handleChangeSavedParameter = (savedParamId, savedParamName) => {
    setSelectedSavedParam({ id: savedParamId, name: savedParamName });
  };

  /**
   * Applies the selected saved parameters to the form fields,
   * overriding any existing values in pathGenParams.
   */
  const handleApplySavedParameters = () => {
    setPathGenParams((prevParams) => {
      const updatedParams = { ...prevParams };

      // eslint-disable-next-line no-restricted-syntax
      for (const [localKey, serverKey] of Object.entries(PATH_PARAMS_MAP)) {
        if (selectedSavedParamData && selectedSavedParamData[serverKey] !== undefined) {
          updatedParams[localKey] = selectedSavedParamData[serverKey];
        }
      }

      return updatedParams;
    });
  };

  /**
   * Handler for saving path generation parameters for future use
   * @param {String} paramName Given name
   * @param {Object} currentPathParams An object containing parameter values and their keys
   */
  const handleSaveParameters = async (paramName, currentPathParams) => {
    await savePathParameters(
      selectedSubBlockId,
      paramName,
      currentPathParams.driveLine,
      currentPathParams.panelWidth,
      currentPathParams.drivewayWidth,
      currentPathParams.deckToPileDistance,
      currentPathParams.robotToPanelDistance,
      currentPathParams.targetOffset
    );
    setShowSaveParamsDialogue(false);
    const result = await getSavedPathGenParameters(selectedSubBlockId);
    setSubBlockSavedParameters(result);
  };

  const handlePlotSolarBlocks = () => {
    setPlotSolarBlocks((prev) => prev + 1);
  };

  return (
    <Fade in>
      <Grid container direction="row" className={classes.root} spacing={1} style={{ alignItems: '' }}>
        <AlertDialog
          containerRef={containerReference}
          state={invalidPilePointMessage}
          show={showInvalidPilePointAlert}
          onClose={() => setShowInvalidPilePointAlert(false)}
        />
        {selectedSavedParamData && showSavedParametersData && (
          <PathGenerationParamsDisplay
            open={showSavedParametersData}
            onClose={() => setShowSavedParametersData(!showSavedParametersData)}
            data={selectedSavedParamData}
          />
        )}
        {setShowSaveParamsDialogue && (
          <SaveParameterDialog
            open={showSaveParamsDialogue}
            onClose={() => setShowSaveParamsDialogue(false)}
            pathGenParams={pathGenParams}
            onSave={handleSaveParameters}
          />
        )}
        <ProgressModal open={loading} status={progressStatus} message={progressMessage} title="Paths Generator" />
        <Grid item xs={6}>
          <Box className={classes.formWrapper}>
            <Typography variant="h5" gutterBottom>
              {`Parameters For: ${subBlockName || ''}`}
            </Typography>

            {/* Two subcolumns of form inputs */}
            <Box className={classes.inputRows}>
              {/* Left sub-column */}
              <Box className={classes.inputColumn}>
                {/* Sub Block Name */}
                <TextField
                  label="Sub Block Name"
                  variant="outlined"
                  size="small"
                  fullWidth
                  className={classes.inputField}
                  value={subBlockName}
                  onChange={(e) => setSubBlockName(e.target.value)}
                />
                {/* Twin SubBlock */}
                <FormControl variant="outlined" size="small" className={classes.inputField} fullWidth disabled={isOpenArea}>
                  <Typography variant="subtitle2">Twin SubBlock</Typography>
                  <Select value={twinSubBlockId || ''} onChange={(e) => setTwinSubBlockId(e.target.value)}>
                    {twinSubBlockOptions.map((sb) => (
                      <MenuItem value={sb.id} key={sb.id}>
                        {sb.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                {/* Twin SubBlock Row Offset */}
                <TextField
                  label="Twin SubBlock Row Offset"
                  variant="outlined"
                  size="small"
                  fullWidth
                  className={classes.inputField}
                  value={twinSubBlockRowOffset}
                  onChange={(e) => setTwinSubBlockRowOffset(e.target.value)}
                  disabled={isOpenArea}
                />

                {/* Drive Line */}
                <TextField
                  label="Drive Line (m)"
                  variant="outlined"
                  size="small"
                  fullWidth
                  className={classes.inputField}
                  value={pathGenParams.driveLine}
                  onChange={(e) => setPathGenParameter('driveLine', e.target.value)}
                  disabled={isOpenArea}
                />

                {/* Panel Width */}
                <TextField
                  label="Panel Width (m)"
                  variant="outlined"
                  size="small"
                  fullWidth
                  className={classes.inputField}
                  value={pathGenParams.panelWidth}
                  onChange={(e) => setPathGenParameter('panelWidth', e.target.value)}
                  disabled={isOpenArea}
                />

                {/* Driveway Width */}
                <TextField
                  label="Driveway Width (m)"
                  variant="outlined"
                  size="small"
                  fullWidth
                  className={classes.inputField}
                  value={pathGenParams.drivewayWidth}
                  onChange={(e) => setPathGenParameter('drivewayWidth', e.target.value)}
                  disabled={isOpenArea}
                />

                {/* Deck To Pile Distance */}
                <TextField
                  label="Deck To Pile Distance (cm)"
                  variant="outlined"
                  size="small"
                  fullWidth
                  className={classes.inputField}
                  value={pathGenParams.deckToPileDistance}
                  onChange={(e) => {
                    const val = e.target.value;
                    if ((pathGenParams.panelWidth && val > (pathGenParams.panelWidth * 60) / 2) || val < 0) {
                      setShowInvalidPilePointAlert(true);
                      setPathGenParameter('deckToPileDistance', 30);
                    } else {
                      setPathGenParameter('deckToPileDistance', val);
                    }
                  }}
                  disabled={isOpenArea}
                />
                <TextField
                  label="Robot to Panel Distance (cm)"
                  variant="outlined"
                  size="small"
                  fullWidth
                  className={classes.inputField}
                  value={pathGenParams.robotToPanelDistance}
                  onChange={(e) => setPathGenParameter('robotToPanelDistance', e.target.value)}
                  disabled={isOpenArea}
                />
              </Box>

              {/* Right sub-column */}
              <Box className={classes.inputColumn}>
                <FormControlLabel
                  control={<Checkbox checked={isOpenArea} color="primary" onChange={() => setIsOpenArea(!isOpenArea)} />}
                  label={<Typography>Open Area</Typography>}
                />

                {/* Path Generation Method */}
                <FormControl variant="outlined" size="small" className={classes.inputField} fullWidth disabled={isOpenArea}>
                  <Typography variant="subtitle2">Path Generation Method</Typography>
                  <Select
                    value={pathGenParams.pathGenerationMethod}
                    onChange={(e) => setPathGenParameter('pathGenerationMethod', e.target.value)}
                  >
                    {GENERATE_METHODS.map((method) => (
                      <MenuItem value={method.value} key={method.value}>
                        {method.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                {/* Path Generation Method Type */}
                {showGenerateMethodType && (
                  <FormControl variant="outlined" size="small" className={classes.inputField} fullWidth disabled={isOpenArea}>
                    <Typography variant="subtitle2">Path Generation Method Type</Typography>
                    <Select
                      value={pathGenParams.pathGenerationMethodType}
                      onChange={(e) => setPathGenParameter('pathGenerationMethodType', e.target.value)}
                    >
                      {generationMethodTypeOptions.map((opt) => (
                        <MenuItem value={opt.value} key={opt.value}>
                          {opt.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}

                {/* Target Offset */}
                <TextField
                  label="Target Offset (cm)"
                  variant="outlined"
                  size="small"
                  fullWidth
                  className={classes.inputField}
                  value={pathGenParams.targetOffset}
                  onChange={(e) => setPathGenParameter('targetOffset', e.target.value)}
                  disabled={isOpenArea}
                />

                {/* Vegetation Thickness */}
                <FormControl variant="outlined" size="small" className={classes.inputField} fullWidth disabled={isOpenArea}>
                  <Typography variant="subtitle2">Vegetation Thickness</Typography>
                  <Select
                    value={pathGenParams.vegetationThickness}
                    onChange={(e) => setPathGenParameter('vegetationThickness', e.target.value)}
                  >
                    {VEGETATION_CONDITION.map((condition) => (
                      <MenuItem key={condition.value} value={condition.value}>
                        {condition.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                {/* Weather Condition */}
                <FormControl variant="outlined" size="small" className={classes.inputField} fullWidth disabled={isOpenArea}>
                  <Typography variant="subtitle2">Weather Condition</Typography>
                  <Select value={pathGenParams.weatherCondition} onChange={(e) => setPathGenParameter('weatherCondition', e.target.value)}>
                    {WEATHER_CONDITION.map((cond) => (
                      <MenuItem key={cond.value} value={cond.value}>
                        {cond.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                {/* Inter-Row Mode */}
                <FormControl variant="outlined" size="small" className={classes.inputField} fullWidth disabled={isOpenArea}>
                  <Typography variant="subtitle2">Inter-Row Mode</Typography>
                  <Select value={pathGenParams.interRowMode} onChange={(e) => setPathGenParameter('interRowMode', e.target.value)}>
                    {INTER_ROW_MODES.map((mode) => (
                      <MenuItem key={mode.value} value={mode.value}>
                        {mode.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            </Box>

            {/* Buttons grouped: first group is for saved params, second group is for CSV & Generate */}
            <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
              {/* Group 1: View, Apply, Save, Reset */}
              <Box className={classes.savedParamButtonsGroup}>
                {/* Row for the "Saved Parameters" select */}
                <Box className={classes.savedParamsRow}>
                  <FormControl variant="outlined" size="small" style={{ minWidth: 160 }} disabled={isOpenArea}>
                    <Typography variant="subtitle2">Saved Parameters</Typography>
                    <Select
                      name={selectedSavedParam.name}
                      value={selectedSavedParam.id}
                      onChange={(event) => {
                        const { name, value } = event.target;
                        handleChangeSavedParameter(value, name);
                      }}
                    >
                      {subBlockSavedParameters.map((savedParam) => (
                        <MenuItem key={savedParam.id} value={savedParam.id}>
                          {savedParam.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
                <Button
                  disabled={isOpenArea}
                  style={{ height: '50%', marginTop: '23px' }}
                  variant="contained"
                  onClick={() => setShowSavedParametersData(true)}
                >
                  PREVIEW
                </Button>
                <Button
                  disabled={isOpenArea}
                  style={{ height: '50%', marginTop: '23px' }}
                  variant="contained"
                  onClick={handleApplySavedParameters}
                >
                  APPLY
                </Button>
                <Button
                  disabled={isOpenArea}
                  style={{ height: '50%', marginTop: '23px' }}
                  variant="contained"
                  onClick={handleResetParameters}
                >
                  RESET
                </Button>
                <Button
                  disabled={isOpenArea}
                  style={{ height: '50%', marginTop: '23px' }}
                  variant="contained"
                  onClick={() => setShowSaveParamsDialogue(true)}
                >
                  SAVE
                </Button>
              </Box>

              {/* Group 2: Upload CSV, Generate */}
              <Box className={classes.csvAndGenerateGroup}>
                <label htmlFor="raised-button-file">
                  <input style={{ display: 'none' }} onChange={(e) => setFileInput(e.target.files)} id="raised-button-file" type="file" />
                  <Tooltip sx={{ fontSize: '2rem' }} title={`${surveyFile ? surveyFile.toString() : 'No Upload'}`}>
                    <Button variant="contained" component="span" disabled={isOpenArea}>
                      {`${surveyFile ? 'Existing File Attached' : 'UPLOAD CSV'}`}
                    </Button>
                  </Tooltip>
                </label>
                <Button variant="contained" color="secondary" onClick={handleGenerateSolarRows}>
                  {isOpenArea ? 'Add Open Area' : 'Generate Subrows'}
                </Button>
              </Box>
            </Box>
          </Box>

          {/* Subrows Table below the form */}
          <Box className={classes.subrowsContainer}>
            <SubrowsUpdateTable data={selectedSubrows} setSubrowsToModify={setSubrowsToModify} isOpenArea={isOpenArea} />
          </Box>
        </Grid>

        {/* RIGHT COLUMN (map + 2 bottom buttons, filling full height) */}
        <Grid item xs={6} className={classes.rightColumn}>
          <Box style={{ width: '1500px', height: '600px' }}>
            <RobotsMapView
              plotSolarProperty={0}
              plotSolarBlocks={plotSolarBlocks}
              isMapReset={isMapReset}
              setIsMapReset={setIsMapReset}
              subBlockId={selectedSubBlockId}
            />
          </Box>
          <Box className={classes.mapButtonsRow}>
            <Button color="secondary" onClick={() => handlePlotSolarBlocks()} variant="contained">
              View Paths
            </Button>
            <Button onClick={() => setIsMapReset(true)} variant="contained">
              Reset
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Fade>
  );
});
